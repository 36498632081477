<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="SessionProtokolle"
                class="elevation-1 kath-sz-card"
                :pagination.sync="pagination"
        >
            <template slot="items" slot-scope="props">
                <tr @click.stop="navPage(props.item)"
                    class="link"
                >
                    <td>{{ props.item.title }}</td>
                    <td>{{ props.item.sessionDate }}</td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import router from '../../router';
    import VDataTable from "vuetify/es5/components/VDataTable/VDataTable";
    export default {
        name: "Protokolle",
        components: {VDataTable},
        methods: {
            navPage(item) {
                if(item.id!==undefined){
                    router.push({name: 'ProtokolleDetailed', params: {id: item.id}});
                } else {
                    window.open(item.file, '_')
                }
            },
        },
        computed: {
            SessionProtokolle () {
                return this.$store.getters.SessionProtokolle
            },
            headers () {
                return this.$store.getters.ProtokollTableHeaders
            },
            pagination () {
                return this.$store.getters.ProtokollTablePagination
            }
        }
    }
</script>

<style scoped>

</style>